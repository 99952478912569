import React, { useState } from "react"
import { useSpring, animated } from "react-spring"
import useResizeAware from "react-resize-aware"

const ShowHideItem = ({ title, subTitle, description, number }) => {
  const [isOpen, toggleOpen] = useState(false)
  const [resizeListener, { height }] = useResizeAware()

  const animProps = useSpring({
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
  })

  return (
    <div className="policy__item" style={{ position: "relative" }}>
      <h2 className="policy__item__title" onClick={() => toggleOpen(!isOpen)}>
        <span>{title}</span>
      </h2>

      <animated.div
        className="showHideItem__elemtn policy__item__description"
        style={{ ...animProps }}
      >
        <div style={{ position: "relative" }}>
          {resizeListener}
          {subTitle ? (
            <p className="policy__item__subtitle">{subTitle}</p>
          ) : null}
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        </div>
      </animated.div>
    </div>
  )
}

export default ShowHideItem
