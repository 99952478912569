import styled from "styled-components"

const PolicyStyled = styled.div`
  overflow: hidden;
  padding-top: 60px;
  position: relative;
  .projects__item {
    &:last-of-type {
      .policy__item__description > div {
        padding-bottom: 0px;
      }
    }
  }
  .policy__container {
    padding-top: 20px;
    padding-bottom: 100px;
    max-width: 690px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .policy__item__title {
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
  }
  .projects__item__description {
    padding-left: 160px;
    & > div {
      padding-bottom: 100px;
    }
  }
  .policy__item__subtitle {
    font-weight: 300;
    &:after {
      margin-top: 20px;
      content: "";
      display: block;
      width: 65px;
      height: 1px;
      background: #000000;
    }
  }
  p {
    margin: 0;
    padding: 10px 0;
  }
`

export default PolicyStyled
